<template>
  <div class="navigation resnone">
    <!-- <router-link to="/Dashboard" class="dash_navs dashboard_nav">
      <span class="fa fa-home"></span> Dashboard</router-link
    > -->
     <router-link to="/product-list" class="dash_navs">
      <span class="fa fa-list-alt"></span> Product List
    </router-link>
    
    <router-link to="/add-product" class="dash_navs">
      <span class="fa fa-plus"></span> Add Products</router-link
    >
   <router-link to="/view-users" class="dash_navs">
      <span class="fa fa-address-book"></span> Users List
    </router-link>

    <router-link to="/admin-list" class="dash_navs">
      <span class="fa fa-users"></span> Store Admins
    </router-link>

    <br />
    <hr />
   
    <span @click="logout()" class="dash_navs"
      ><span class="fa fa-sign-out-alt"></span> Log out</span
    >
    <!-- target="_blank" -->
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

import $ from "jquery";

export default {
  name: "Nav",
  components: {},
  mounted() {
    AOS.init();
  },
  methods: {
    logout() {
      localStorage.clear();
      window.location = "/";
    },
  },
};
</script>

<style scoped>
.none {
  display: none;
}

/* #7756e6 */
/* box-shadow: 4px 4px 4px 4px rgba(199, 199, 199, 0.805) ; */
.dash_navs {
  display: block;
  margin-top: 16px;
  margin-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  text-decoration: none;
  color: black;
  font-size: 21px;
  width: 100%;
  cursor: pointer;
}
/* .dashboard_nav{color:#dc3545;border-right: 4px solid #dc3545;font-weight: bold;} */

.othernvs {
  font-size: 15px;
}
.navigation {
  width: 15%;
  background-color: white;
  height: 100%;
  position: fixed;
  z-index: 2;
  top: 10%;
  box-shadow: 4px 4px 4px 4px rgba(199, 199, 199, 0.805);
  /* border-top:1px solid black; */
  /* border-right: 1px solid black; */
}
/* border-top-right-radius: 2%;border-Bottom-right-radius: 2%; */
.navimg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.router-link-active {
  color: #dc3545;
  font-weight: bolder;
  background-color: rgba(244, 244, 244, 0.948);
  border-right: 4px solid #dc3545;
}

.dash_navs:hover {
  color: #dc3545;
  font-weight: bold;
}
/* new rules to be added */
.logout {
  color: #dc3545;
  margin-top: 50px;
  text-align: right;
  padding: 10px;
  font-weight: bold;
}

@media screen and (max-width: 780px) {
  .resnone {
    display: none;
  }
}

@media screen and (max-width: 1580px) {
  .navigation {
  top: 13%;

}
}
</style>
