<template>
    <div class="cont">
        <div class="row r1">
            <div class="col-md-2"><Nav class="resnone" /></div>
            <div class="col-md-10">
                <h5 class="abouthead">Users Table</h5>

                <div class="process_form">
                    <div class="table-responsive" data-aos="slide-up" style="overflow-x: scroll">
                        <table class="table table-bordered" style="overflow-x: scroll">
                            <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>State</th>
                                    <th>Address</th>
                                    <th>Interest</th>
                                    <th>Date Created</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in response_data" v-bind:key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.name }}</td>
                                    <td>{{ data.email }}</td>
                                    <td>{{ data.phone_number }}</td>
                                    <td>{{ data.state }}</td>
                                    <td>{{ data.address }}</td>
                                    <td>{{ data.interested_product }}</td>
                                    <td>{{ formatDate(data.created_at) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-if="response_data == null" class="empty">
                        <span class="fa fa-box-open empty_list"></span>
                    </div>
                </div>

                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
            </div>
        </div>
    </div>
</template>

<script>
// import $ from "jquery"
// import axios from 'axios'

import Nav from "./plugins/Sidenav.vue";

// import AOS from 'aos'
import "aos/dist/aos.css";
import {api, url} from "./../api";

export default {
    name: "admin_list",
    components: {
        Nav,
    },

    data() {
        return {
            response_data: "",
        };
    },
    methods: {
        formatDate(dateString) {
            const date = new Date(dateString);

            // Options to format the date
            const options = {year: "numeric", month: "long", day: "numeric"};
            const formattedDate = date.toLocaleDateString("en-US", options);

           return formattedDate;
        },
        view_admins() {
            api.get(url("admin/view/contact"))
            .then((response) => {
                this.response_data = response.data.data;
                console.log(this.response_data);
            })
            .catch(function (error) {
                console.log("Error", error.response.data.message);
            });
        },

        // delete_user(D) {
        //   swal({
        //     title: "Are you sure?",
        //     text: "Once deleted, you will not be able to recover its files!",
        //     icon: "warning",
        //     buttons: true,
        //     dangerMode: true,
        //   }).then((willDelete) => {
        //     if (willDelete) {
        //       swal("User has been deleted!", {
        //         icon: "success",
        //       });
        //       api
        //         .get(url("admin/delete/admins"), { params: { id: D } })
        //         //   api.get(url("admin/delete/admins/"))
        //         .then((response) => {
        //           this.returned = response.data;
        //           console.log(this.returned);
        //           this.view_admins();

        //           if (this.returned[0] == true) {
        //           }
        //         });
        //     } else {
        //       swal("Delete cancelled");
        //     }
        //   });
        // },
        // gotoAdminProfile(admin_details) {
        //   localStorage.setItem("admin_details", JSON.stringify(admin_details));

        //   this.$router.push("/edit-profile");
        // },
    },
    mounted() {
        this.view_admins();
    },
};
</script>

<style scoped>
.none,
.desknone {
    display: none;
}
.cont {
    padding: 0px;
    margin-top: 120px;
    color: black;
    font-size: 15px;
    overflow-x: hidden;
}
.table-responsive {
    overflow: visible;
}
.dropdown-item {
    cursor: pointer;
}
body {
    overflow-x: hidden;
}
.abouthead {
    padding-left: 40px;
}
.process_form {
    padding: 40px;
    padding-top: 20px;
}

.empty_list {
    font-size: 100px;
    display: inline-block;
    color: #dc3545;
}
.empty {
    text-align: center;
    padding-top: 10%;
}
@media screen and (max-width: 480px) {
}
@media screen and (max-width: 780px) {
    .process_form {
        padding: 5px;
        padding-top: 20px;
    }
    .table {
        overflow-x: scroll;
    }
    .abouthead {
        padding-left: 5px;
    }
    .empty_list {
        font-size: 70px;
        display: inline-block;
        color: #dc354650;
    }
    .empty {
        text-align: center;
        padding-top: 20%;
    }
    .cont {
        padding: 5px;
        margin-top: 80px;
    }
}
@media screen and (max-width: 1280px) {
    /* .cont  {
    padding: 5px;
    margin-top: 0px;
  } */
}
</style>
