<template>
  <div class="resmenu desknone">
    <div class="search-bar">
      <div class="profilehead_cont">
        <span class="welcome">Welcome back!</span>
        <span class="name">{{ name }}</span>
      </div>
    </div>
    <div class="mmenucont">
      <router-link to="/product-list" class="res_navs">
        <span class="fa fa-list-alt"></span> Product List
      </router-link>
      
      <router-link to="/add-product" class="res_navs">
        <span class="fa fa-plus"></span> Add Products</router-link
      >

       <router-link to="/view-users" class="res_navs">
      <span class="fa fa-address-book"></span> Users List
    </router-link>
      
      <router-link to="/admin-list" class="res_navs">
        <span class="fa fa-users"></span> Store Admins
      </router-link>

      <hr />
      <span @click="logout()" class="res_navs"
        ><span class="fa fa-sign-out-alt"></span> Log out</span
      >
    </div>

    <!-- <NuxtLink to="/Login" class="action_btn rm">LOG OUT <img src="../../assets/a2.png" alt="" class="rmimg"></NuxtLink> -->
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "sidemenu",
  components: {},
  data() {
    return {
      name: localStorage.getItem("admin_name"),
    };
  },
  methods: {
    logout() {
      localStorage.clear();
      window.location = "/";
    },
  },
  mounted() {
    $(".res_navs").click(function () {
      $(".resmenu").hide();
      $(".bar").show();
      $(".x").hide();
    });
  },
};
</script>

<style scoped>
.desknone{display: none;}
/* rgba(255, 255, 255, 0.935) */
@media screen and (max-width: 780px) {
  .res_navs {
    margin-top: 20px;
    margin-bottom: 30px;
    padding-left: 10px;
    font-size: 24px;
    text-decoration: none;
    color: black;
    display: block;
  }
  .resmenu {
    padding-bottom: 20px;
    width: 80%;
    display: none;
    padding: 0px;
    color: black;
    background-color: white;
    position: fixed;
    left: 0%;
    top: 70px;
    height: 100%;
    backdrop-filter: blur(5px);
    z-index: 999;
  box-shadow: 2px 2px 2px 2px rgba(199, 199, 199, 0.805);

  }
  .mmenucont {
    margin-top: 20px;
  }
  .rm {
    margin-top: 30px;
    font-size: 14px;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 20px;
  }
  .rmimg {
    height: 17px;
    width: 17px;
    margin-left: 5px;
  }
  .router-link-active {
    color: #dc3545;
    /* font-weight: bolder;
  background-color: rgba(244, 244, 244, 0.948);
  border-right: 4px solid #dc3545; */
  }

  .fa {
    color: black;
    font-size: 26px;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 2px;
  }

  .search-bar {
    align-items: center;
    width: 96%;
    margin-left: 2%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .search-bar select {
    padding: 10px;
    border-radius: 5px;
    border: 2px solid black;
    outline: none;
    display: block;
    width: 100%;
  }

  .name {
    color: black;
    font-size: 24px;
    margin-right: 10px;
  }

  .welcome {
    color: #dc3545;
    font-size: 26px;
    margin-right: 10px;
  }
}
</style>
