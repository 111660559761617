import {
  createRouter,
  // createWebHistory,
  createWebHashHistory,
} from "vue-router";

// admin routes
import Login from "../components/Login";
import Signgnup from "../components/Signup";
// import Dashboard from "../components/Dashboard";
import Add_post from "../components/Add_post";
import Product_list from "../components/Product_list";
// import Profile from "../components/Profile";
import Admins_list from "../components/Admins_list";
import Edit_profile from "../components/Edit_profile";
import Edit_product from "../components/Edit_product";
import Users_List from "../components/Users_List";

const routes = [
  { path: "/", name: "Login", component: Login },
  { path: "/Sign-up", name: "Signup", component: Signgnup },
  // { path: "/Dashboard", name: "Dashboard", component: Dashboard },
  { path: "/add-product", name: "Add_post", component: Add_post },
  { path: "/product-list", name: "Product_list", component: Product_list },
  // { path: "/Profile", name: "Profile", component: Profile },
  { path: "/admin-list", name: "Admins_list", component: Admins_list },
  { path: "/edit-profile", name: "Edit_profile", component: Edit_profile },
  { path: "/edit-product", name: "Edit_product", component: Edit_product },
  { path: "/view-users", name: "Users_List", component: Users_List },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
// const router = createRouter({
//   history: createWebHistory(),
//   routes,
// });

export default router;
